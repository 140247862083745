exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-book-a-car-index-tsx": () => import("./../../../src/pages/book-a-car/index.tsx" /* webpackChunkName: "component---src-pages-book-a-car-index-tsx" */),
  "component---src-pages-book-a-car-records-tsx": () => import("./../../../src/pages/book-a-car/records.tsx" /* webpackChunkName: "component---src-pages-book-a-car-records-tsx" */),
  "component---src-pages-book-an-apartment-index-tsx": () => import("./../../../src/pages/book-an-apartment/index.tsx" /* webpackChunkName: "component---src-pages-book-an-apartment-index-tsx" */),
  "component---src-pages-components-date-picker-index-tsx": () => import("./../../../src/pages/components/datePicker/index.tsx" /* webpackChunkName: "component---src-pages-components-date-picker-index-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */)
}

